<template>
  <div>
    <div id="scroll-top"></div>
    <div id="the-outer-document">
      <div
        v-for="(page, index) in contractPages"
        class="contract-table"
        :id="`doc-${page.id}`"
        :key="index"
      >
        <div
          v-if="
            (page.data.length > 0 && contractPages[index].check) ||
            (page.data.length > 0 && contractPages[index].check == undefined)
          "
        >
          <div
            v-for="(a4, index) in filterParagraphCols(page.data)"
            :key="index"
            style="line-height: 14px"
          >
            <table id="a4" :class="'a4'" width="100%" border="0">
              <tbody>
                <tr>
                  <td class="td">
                    <table v-if="a4.page == '1'" width="100%" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding-top: 5px !important">
                            <img
                              :src="
                                contract.company_branch.logo
                                  ? contract.company_branch.logo
                                  : page.company_branch.logo
                              "
                              :alt="contract.company_branch.name"
                              style="height: 50px; margin-bottom: 5px"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table v-if="a4.page == '1'" width="100%" border="0">
                      <tbody>
                        <tr v-if="page.title">
                          <td
                            align="center"
                            style="
                              font-size: 16px;
                              line-height: 1.2;
                              padding-bottom: 5px !important;
                              color: #666;
                            "
                          >
                            <strong
                              style="font-weight: 600"
                              v-html="page.title"
                            ></strong>
                          </td>
                        </tr>
                        <tr v-if="page.subtitle">
                          <td
                            align="center"
                            style="
                              font-size: 14px;
                              padding-bottom: 25px !important;
                              color: #666;
                            "
                          >
                            <strong
                              style="font-weight: 600"
                              v-html="page.subtitle"
                            ></strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div style="column-count: 1">
                      <div
                        v-for="(p, index) in a4.paragraphs.cols1"
                        :key="index"
                      >
                        <div
                          :style="{
                            fontSize:
                              p.css != undefined ? p.css.font.size : 'inherit',
                            borderStyle: p.css.borderWidth.top ? 'solid' : '',
                            borderColor:
                              p.css != undefined ? p.css.boderColor : 'inherit',
                            borderWidth:
                              p.css != undefined
                                ? `${p.css.borderWidth.top} ${p.css.borderWidth.right} ${p.css.borderWidth.bottom} ${p.css.borderWidth.left}`
                                : 'inherit',
                            marginTop:
                              p.css != undefined ? p.css.margin.top : 'inherit',
                            marginRight:
                              p.css != undefined
                                ? p.css.margin.right
                                : 'inherit',
                            marginBottom:
                              p.css != undefined
                                ? p.css.margin.bottom
                                : 'inherit',
                            marginLeft:
                              p.css != undefined
                                ? p.css.margin.left
                                : 'inherit',
                            paddingTop:
                              p.css != undefined
                                ? p.css.padding.top
                                : 'inherit',
                            paddingRight:
                              p.css != undefined
                                ? p.css.padding.right
                                : 'inherit',
                            paddingBottom:
                              p.css != undefined
                                ? p.css.padding.bottom
                                : 'inherit',
                            paddingLeft:
                              p.css != undefined
                                ? p.css.padding.left
                                : 'inherit',
                          }"
                          v-html="p.p"
                        ></div>
                      </div>
                    </div>

                    <div style="column-count: 2">
                      <div
                        v-for="(p, index) in a4.paragraphs.cols2"
                        :key="index"
                      >
                        <div
                          :style="{
                            fontSize:
                              p.css != undefined ? p.css.font.size : 'inherit',
                            borderStyle: p.css.borderWidth.top ? 'solid' : '',
                            borderColor:
                              p.css != undefined ? p.css.boderColor : 'inherit',
                            borderWidth:
                              p.css != undefined
                                ? `${p.css.borderWidth.top} ${p.css.borderWidth.right} ${p.css.borderWidth.bottom} ${p.css.borderWidth.left}`
                                : 'inherit',
                            marginTop:
                              p.css != undefined ? p.css.margin.top : 'inherit',
                            marginRight:
                              p.css != undefined
                                ? p.css.margin.right
                                : 'inherit',
                            marginBottom:
                              p.css != undefined
                                ? p.css.margin.bottom
                                : 'inherit',
                            marginLeft:
                              p.css != undefined
                                ? p.css.margin.left
                                : 'inherit',
                            paddingTop:
                              p.css != undefined
                                ? p.css.padding.top
                                : 'inherit',
                            paddingRight:
                              p.css != undefined
                                ? p.css.padding.right
                                : 'inherit',
                            paddingBottom:
                              p.css != undefined
                                ? p.css.padding.bottom
                                : 'inherit',
                            paddingLeft:
                              p.css != undefined
                                ? p.css.padding.left
                                : 'inherit',
                          }"
                          v-html="p.p"
                        ></div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div style="page-break-after: always"></div>
          <div class="html2pdf__page-break avoidThisRow"></div>
        </div>

        <div v-if="page.data.length == 0">
          <div
            :id="`doc-${page.id}`"
            v-if="
              (page.id == '10' && page.check) ||
              (page.id == '10' && page.check == undefined)
            "
          >
            <TravelContractThirdPartyPayment
              v-for="(terceiro, index) in contrato.pagamento.terceiros.lista"
              :key="index"
              :index="index"
              :page="page.subpages"
              :anchorId="`doc-${page.id}-${terceiro.id}`"
              :terceiro="terceiro"
              :contract="contract"
              :contrato="contrato"
              :logo="contract.company_branch.logo"
              :temCartao="contrato.pagamento.credito.ativo"
              :temBoleto="contrato.pagamento.boleto.ativo"
              :temTransferencia="contrato.pagamento.transferencia.ativo"
            />
          </div>

          <div
            :id="`doc-${page.id}`"
            v-if="
              (page.id == '9' && page.check) ||
              (page.id == '9' && page.check == undefined)
            "
          >
            <TravelContractDebitAuthorization
              v-for="(cartao, index) in contrato.pagamento.credito.cartao"
              :key="index"
              :page="page.subpages"
              :anchorId="`doc-${page.id}-${cartao.id}`"
              :idCartao="cartao.id"
              :logo="contract.company_branch.logo"
              :cartao="cartao"
              :contract="contract"
              :contrato="contrato"
            />
          </div>
        </div>
      </div>

      <div id="cartao">
        <div id="the-document" class="contract-table"></div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import TravelContractThirdPartyPayment from "@/components/contracts/documents/v2/TravelContractThirdPartyPayment.vue";
import TravelContractDebitAuthorization from "@/components/contracts/documents/v2/TravelContractDebitAuthorization.vue";

export default {
  name: "ContractPreviewDocumentsSection",
  props: {
    contract: Object,
    contrato: Object,
    contractPages: Array,
  },
  components: {
    TravelContractThirdPartyPayment,
    TravelContractDebitAuthorization,
  },
  methods: {
    filterParagraphCols(paragraphs) {
      let breakPages = _.groupBy(paragraphs, (item) => item.page),
        values = Object.values(breakPages),
        keys = Object.keys(breakPages),
        allPages = keys.map((travel, index) => {
          return {
            page: travel,
            paragraphs: values[index],
          };
        });

      allPages = allPages.map((page) => {
        return {
          ...page,
          paragraphs: {
            cols1: _.groupBy(page.paragraphs, (item) => item.css.cols)["1"],
            cols2: _.groupBy(page.paragraphs, (item) => item.css.cols)["2"],
          },
        };
      });

      return allPages;
    },
  },
};
</script>

<style lang="sass" scoped></style>

<style>
.contract-table {
  margin: 0 auto;
  width: 793px;
}

.contract-table .a4 {
  background: #fff;
  margin-bottom: 20px;
  height: 1122px;
}

.contract-table .a4 .td {
  padding: 20px;
  height: 1112px;
  vertical-align: top;
  color: #666;
  font-size: 13px;
}
</style>
