var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{attrs:{"id":"scroll-top"}}),_vm._v(" "),_c('div',{attrs:{"id":"the-outer-document"}},[_vm._l((_vm.contractPages),function(page,index){return _c('div',{key:index,staticClass:"contract-table",attrs:{"id":`doc-${page.id}`}},[(
          (page.data.length > 0 && _vm.contractPages[index].check) ||
          (page.data.length > 0 && _vm.contractPages[index].check == undefined)
        )?_c('div',[_vm._l((_vm.filterParagraphCols(page.data)),function(a4,index){return _c('div',{key:index,staticStyle:{"line-height":"14px"}},[_c('table',{class:'a4',attrs:{"id":"a4","width":"100%","border":"0"}},[_c('tbody',[_c('tr',[_c('td',{staticClass:"td"},[(a4.page == '1')?_c('table',{attrs:{"width":"100%","border":"0"}},[_c('tbody',[_c('tr',[_c('td',{staticStyle:{"padding-top":"5px !important"},attrs:{"valign":"top"}},[_c('img',{staticStyle:{"height":"50px","margin-bottom":"5px"},attrs:{"src":_vm.contract.company_branch.logo
                                ? _vm.contract.company_branch.logo
                                : page.company_branch.logo,"alt":_vm.contract.company_branch.name}})])])])]):_vm._e(),_vm._v(" "),(a4.page == '1')?_c('table',{attrs:{"width":"100%","border":"0"}},[_c('tbody',[(page.title)?_c('tr',[_c('td',{staticStyle:{"font-size":"16px","line-height":"1.2","padding-bottom":"5px !important","color":"#666"},attrs:{"align":"center"}},[_c('strong',{staticStyle:{"font-weight":"600"},domProps:{"innerHTML":_vm._s(page.title)}})])]):_vm._e(),_vm._v(" "),(page.subtitle)?_c('tr',[_c('td',{staticStyle:{"font-size":"14px","padding-bottom":"25px !important","color":"#666"},attrs:{"align":"center"}},[_c('strong',{staticStyle:{"font-weight":"600"},domProps:{"innerHTML":_vm._s(page.subtitle)}})])]):_vm._e()])]):_vm._e(),_vm._v(" "),_c('div',{staticStyle:{"column-count":"1"}},_vm._l((a4.paragraphs.cols1),function(p,index){return _c('div',{key:index},[_c('div',{style:({
                          fontSize:
                            p.css != undefined ? p.css.font.size : 'inherit',
                          borderStyle: p.css.borderWidth.top ? 'solid' : '',
                          borderColor:
                            p.css != undefined ? p.css.boderColor : 'inherit',
                          borderWidth:
                            p.css != undefined
                              ? `${p.css.borderWidth.top} ${p.css.borderWidth.right} ${p.css.borderWidth.bottom} ${p.css.borderWidth.left}`
                              : 'inherit',
                          marginTop:
                            p.css != undefined ? p.css.margin.top : 'inherit',
                          marginRight:
                            p.css != undefined
                              ? p.css.margin.right
                              : 'inherit',
                          marginBottom:
                            p.css != undefined
                              ? p.css.margin.bottom
                              : 'inherit',
                          marginLeft:
                            p.css != undefined
                              ? p.css.margin.left
                              : 'inherit',
                          paddingTop:
                            p.css != undefined
                              ? p.css.padding.top
                              : 'inherit',
                          paddingRight:
                            p.css != undefined
                              ? p.css.padding.right
                              : 'inherit',
                          paddingBottom:
                            p.css != undefined
                              ? p.css.padding.bottom
                              : 'inherit',
                          paddingLeft:
                            p.css != undefined
                              ? p.css.padding.left
                              : 'inherit',
                        }),domProps:{"innerHTML":_vm._s(p.p)}})])}),0),_vm._v(" "),_c('div',{staticStyle:{"column-count":"2"}},_vm._l((a4.paragraphs.cols2),function(p,index){return _c('div',{key:index},[_c('div',{style:({
                          fontSize:
                            p.css != undefined ? p.css.font.size : 'inherit',
                          borderStyle: p.css.borderWidth.top ? 'solid' : '',
                          borderColor:
                            p.css != undefined ? p.css.boderColor : 'inherit',
                          borderWidth:
                            p.css != undefined
                              ? `${p.css.borderWidth.top} ${p.css.borderWidth.right} ${p.css.borderWidth.bottom} ${p.css.borderWidth.left}`
                              : 'inherit',
                          marginTop:
                            p.css != undefined ? p.css.margin.top : 'inherit',
                          marginRight:
                            p.css != undefined
                              ? p.css.margin.right
                              : 'inherit',
                          marginBottom:
                            p.css != undefined
                              ? p.css.margin.bottom
                              : 'inherit',
                          marginLeft:
                            p.css != undefined
                              ? p.css.margin.left
                              : 'inherit',
                          paddingTop:
                            p.css != undefined
                              ? p.css.padding.top
                              : 'inherit',
                          paddingRight:
                            p.css != undefined
                              ? p.css.padding.right
                              : 'inherit',
                          paddingBottom:
                            p.css != undefined
                              ? p.css.padding.bottom
                              : 'inherit',
                          paddingLeft:
                            p.css != undefined
                              ? p.css.padding.left
                              : 'inherit',
                        }),domProps:{"innerHTML":_vm._s(p.p)}})])}),0)])])])])])}),_vm._v(" "),_c('div',{staticStyle:{"page-break-after":"always"}}),_vm._v(" "),_c('div',{staticClass:"html2pdf__page-break avoidThisRow"})],2):_vm._e(),_vm._v(" "),(page.data.length == 0)?_c('div',[(
            (page.id == '10' && page.check) ||
            (page.id == '10' && page.check == undefined)
          )?_c('div',{attrs:{"id":`doc-${page.id}`}},_vm._l((_vm.contrato.pagamento.terceiros.lista),function(terceiro,index){return _c('TravelContractThirdPartyPayment',{key:index,attrs:{"index":index,"page":page.subpages,"anchorId":`doc-${page.id}-${terceiro.id}`,"terceiro":terceiro,"contract":_vm.contract,"contrato":_vm.contrato,"logo":_vm.contract.company_branch.logo,"temCartao":_vm.contrato.pagamento.credito.ativo,"temBoleto":_vm.contrato.pagamento.boleto.ativo,"temTransferencia":_vm.contrato.pagamento.transferencia.ativo}})}),1):_vm._e(),_vm._v(" "),(
            (page.id == '9' && page.check) ||
            (page.id == '9' && page.check == undefined)
          )?_c('div',{attrs:{"id":`doc-${page.id}`}},_vm._l((_vm.contrato.pagamento.credito.cartao),function(cartao,index){return _c('TravelContractDebitAuthorization',{key:index,attrs:{"page":page.subpages,"anchorId":`doc-${page.id}-${cartao.id}`,"idCartao":cartao.id,"logo":_vm.contract.company_branch.logo,"cartao":cartao,"contract":_vm.contract,"contrato":_vm.contrato}})}),1):_vm._e()]):_vm._e()])}),_vm._v(" "),_vm._m(0)],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"cartao"}},[_c('div',{staticClass:"contract-table",attrs:{"id":"the-document"}})])
}]

export { render, staticRenderFns }